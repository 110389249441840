import React, { useContext } from "react"
import { Box, TextField, InputAdornment } from '@mui/material';

import InputButton from "./InputButton";
import InputLabel from "./InputLabel";

import { UserDetailsContext } from "../../../contexts/UserContext";
import { colors } from "../../../utils/colors";
import { textFieldStyles } from "../utils";

const InputField = ({ betAmount, setBetAmount, disabled = false }:
  { betAmount: number, setBetAmount: React.Dispatch<React.SetStateAction<number>>, disabled?: boolean }) => {

  const { balance } = useContext(UserDetailsContext);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBetAmount(Number(event.target.value));
  }
  const handleMultiplierButton = (multiplier: number) => {
    setBetAmount(betAmount * multiplier);
  }

  const handleMaxButton = () => {
    setBetAmount(parseFloat(balance.includes('?') ? '0' : balance.replace(' MCOQ', '')));
  }

  return (
    <Box sx={{ width: '100%', paddingY: '0px' }}>
      <div>
        <InputLabel betAmount={betAmount} />
        <TextField
          disabled={disabled}
          value={betAmount ? betAmount : ''}
          onChange={handleInputChange}
          id="outlined-basic"
          variant="outlined"
          label="MCOQ"
          type="number"
          autoComplete="off"
          InputLabelProps={{
            style: { color: colors.freshWhite },
          }}
          sx={textFieldStyles}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InputButton disabled={disabled} onClick={() => handleMultiplierButton(0.5)} sx={{ mx: '2px' }}>½</InputButton>
                <InputButton disabled={disabled} onClick={() => handleMultiplierButton(2)} sx={{ mx: '2px' }}>2×</InputButton>
                <InputButton disabled={disabled} onClick={handleMaxButton} sx={{ mx: '2px' }}>Max</InputButton>
              </InputAdornment>
            ),
          }}
        >
        </TextField>
      </div>
    </Box>
  )
}

export default InputField