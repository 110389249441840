import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Box  } from '@mui/material';

import { useQuery } from '@apollo/client';
import { GET_BET } from '../../graphql/queries';
import { colors } from '../../utils/colors';
import BetCard from './BetCard';

const BetCardModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const betId = searchParams.get('betId');
  const modal = searchParams.get('modal');

  // Используем betId для запроса данных о ставке
  const { data, loading, error } = useQuery(GET_BET, {
    variables: { id: betId },
    skip: !betId,
  });


  const handleClose = () => {
    searchParams.delete('betId');
    searchParams.delete('modal');
    navigate({ search: searchParams.toString() });
  };

  return (
    <Modal open={!!betId && modal === 'bet'} onClose={handleClose} 
    sx={{
      '&:focus-visible': {
        outline: 'none',
      },
    }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 600,
          width: '90%',
          p: '8px',
          border: 'none',
          // boxShadow: 24,
          borderRadius: 2,
          backgroundColor: colors.pink,
          '&:focus-visible': {
            outline: 'none',
          },
        }}
      >

        {loading || !betId ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          <BetCard bet={data.bet}/>
        )}
      </Box>
    </Modal>
  );
};

export default BetCardModal;
