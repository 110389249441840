import { Box, useMediaQuery, useTheme } from '@mui/material';

import Cell from './Cell';
import CashoutModal from './CashoutModal';

import { MinesContext } from '../../../../contexts/MinesContext';
import { useContext } from 'react';
import { gameFieldStyles } from '../../utils';


const MinesGrid = ({ isXL }: { isXL: boolean }) => {
  const { gridSize } = useContext(MinesContext)

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      maxWidth: isXL ? '725px' : '600px',
      marginX: 'auto',
      flexGrow: 1,
    }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${gridSize ** 0.5}, 1fr)`,
          gap: '10px',
          width: '100%'
        }}
      >
        {Array.from({ length: gridSize }, (_, i) => (
          <Cell number={i + 1} key={`gencell${i}`} />
        ))}
      </Box>
    </Box>
  );
};

const GameField = () => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));
  const { isActive, currentMultiplier } = useContext(MinesContext)

  return (
    <Box sx={{ flexGrow: { xl: 1 }, position: 'relative' }}>
      <Box sx={gameFieldStyles}>
        <MinesGrid isXL={isXL} />
      </Box>
      {!isActive && currentMultiplier > 0 && <CashoutModal />}
    </Box>
  );
};

export default GameField;