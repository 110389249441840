import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AirIcon from '@mui/icons-material/Air';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import '@rainbow-me/rainbowkit/styles.css'

import PriceWidget from './PriceWidget/PriceWidget';
import SocialIcons from './SocialIcons';
import { UserDetailsContext } from '../../contexts/UserContext';

const drawerWidth = 165;

type SidebarProps = {
  mobileOpen: boolean,
  handleDrawerClose: () => void
  handleDrawerTransitionEnd: () => void
};

const SidebarLabels = [
  { to: '/', label: 'Sportsbook', icon: <SportsBasketballIcon color='info' />, disabled: false },
  { to: '/', label: 'Competitions', icon: <EmojiEventsIcon color='info' />, disabled: true },
  { to: '/statistics', label: 'Statistics', icon: <EqualizerIcon color='info' />, disabled: false },
  { to: '/betstream', label: 'Bet Activity', icon: <AirIcon color='info' />, disabled: false },

  { to: '/transactions', label: 'Profile', icon: <AccountCircleIcon color='info' />, disabled: true },
  { to: '/', label: 'VIP', icon: <LocalBarIcon color='info' />, disabled: true },
  { to: '/support', label: 'Support', icon: <LiveHelpIcon color='info' />, disabled: false },
];

export default function Sidebar({ mobileOpen, handleDrawerClose, handleDrawerTransitionEnd }: SidebarProps) {
  const { userJWT } = useContext(UserDetailsContext);
  const [profileDisabled, setProfileDisabled] = useState(true);

  useEffect(() => {
    userJWT? setProfileDisabled(false) : setProfileDisabled(true);
  }, [userJWT])

  const drawer = (
    <div>
      <PriceWidget />
      <List>
        {SidebarLabels.slice(0, 4).map((item, index) => (
          <Link to={item.to}
            key={item.label}
            style={{ textDecoration: 'none', color: '#fff', pointerEvents: item.disabled ? 'none' : 'auto' }}
          >
            <ListItem key={item.label} disablePadding>
              <ListItemButton disabled={item.disabled}>
                <ListItemIcon sx={{ width: '35px', minWidth: '35px' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <List>
        {SidebarLabels.slice(4).map((item, index) => (
          <Link to={item.to}
            key={item.label}
            style={{ textDecoration: 'none', color: '#fff', pointerEvents: (item.label === 'Profile'? profileDisabled : item.disabled) ? 'none' : 'auto' }}
          >
            <ListItem key={item.label} disablePadding>
              <ListItemButton disabled={item.label === 'Profile'? profileDisabled : item.disabled}>
                <ListItemIcon sx={{ width: '35px', minWidth: '35px' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <SocialIcons />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={undefined}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
