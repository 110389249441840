import React, { useState, createContext, ReactNode, useEffect } from 'react';
import { getDiceMultiplier } from '../utils/utils';
import { DICE_HOUSE_EDGE } from '../utils/config';

interface DiceContextProps {
  betAmount: number;
  setBetAmount: React.Dispatch<React.SetStateAction<number>>;
  target: number;
  setTarget: React.Dispatch<React.SetStateAction<number>>;
  winChance: number;
  setWinChance: React.Dispatch<React.SetStateAction<number>>;
  currentMultiplier: number;
  setCurrentMultiplier: React.Dispatch<React.SetStateAction<number>>;
  condition: 'below' | 'above';
  setCondition: React.Dispatch<React.SetStateAction<'below' | 'above'>>;
  toggleCondition: () => void;
  pastBets: any[]
  setPastBets: React.Dispatch<React.SetStateAction<any[]>>,
  setTargetAndWinChance: (target?: number, winChance?: number) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DiceContext = createContext<DiceContextProps>({
  betAmount: 0,
  setBetAmount: () => { },
  target: 50,
  setTarget: () => { },
  winChance: 50,
  setWinChance: () => { },
  currentMultiplier: 0,
  setCurrentMultiplier: () => { },
  condition: 'above',
  setCondition: () => { },
  toggleCondition: () => { },
  pastBets: [],
  setPastBets: () => { },
  setTargetAndWinChance: () => {},
  isLoading: false,
  setIsLoading: () => { }
});

export const DiceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [betAmount, setBetAmount] = useState<number>(0);
  const [target, setTarget] = useState<number>(50);
  const [winChance, setWinChance] = useState<number>(50);
  const [currentMultiplier, setCurrentMultiplier] = useState<number>(1.98);
  const [condition, setCondition] = useState<'below' | 'above'>('above');
  const [pastBets, setPastBets] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleCondition = () => {
    setCondition(condition === 'below' ? 'above' : 'below');
  };

  const setTargetAndWinChance = (newTarget?: number, newWinChance?: number) => {
    if (newTarget !== undefined) {
      setTarget(newTarget);
      if (condition === 'below') {
        setWinChance(newTarget);
      } else {
        setWinChance(100 - newTarget);
      }
    } else if (newWinChance !== undefined) {
      setWinChance(newWinChance);
      if (condition === 'below') {
        setTarget(newWinChance);
      } else {
        setTarget(100 - newWinChance);
      }
    }
  };

  useEffect(() => {
    setTargetAndWinChance(target);
  }, [condition]);

  useEffect(() => {
    setCurrentMultiplier(getDiceMultiplier({
      winChance: winChance, 
      houseEdge: DICE_HOUSE_EDGE, 
    }));
  }, [winChance]);

  return (
    <DiceContext.Provider value={{
      betAmount, setBetAmount,
      target, setTarget,
      winChance, setWinChance,
      currentMultiplier, setCurrentMultiplier,
      condition, setCondition, toggleCondition,
      pastBets, setPastBets,
      setTargetAndWinChance,
      isLoading, setIsLoading
    }}>
      {children}
    </DiceContext.Provider>
  );
};