import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { LineChart, Line, BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { green, pink, greyWhite } from '../../utils/colors';
import { getFormattedTotal } from '../../utils/utils';
import httpClient from '../../utils/http-client';
import { McoqPriceContext } from '../../contexts/McoqPriceContext';

const getTotalsData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/totals-data');
  return response.data as any;
};

const getBetsChartData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/bets-chart-data');
  return response.data as any;
};

const getActiveUserChartData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/active-user-chart-data');
  return response.data as any;
};

const getPnlChartData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/pnl-chart-data');
  return response.data as any;
};

const getDepositWithdrawalData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/deposit-withdrawal-chart-data');
  return response.data as any;
};

interface TotalsData {
  totalWithdrawals: number;
  totalDeposits: number;
  totalVolume: number;
  totalBets: number;
  totalPnl: number;
  totalUsers: number;
  totalBurn: number;
}

function Stats() {
  const [totalsData, setTotalsData] = useState<NonNullable<TotalsData>>({
    totalWithdrawals: 0,
    totalDeposits: 0,
    totalVolume: 0,
    totalBets: 0,
    totalPnl: 0,
    totalUsers: 0,
    totalBurn: 0,
  });
  const [betsChartData, setBetsChartData] = useState<Array<unknown>>([]);
  const [pnlChartData, setPnlChartData] = useState<Array<unknown>>([]);
  const [depositWithdrawalChartData, setDepositWithdrawalChartData] = useState<Array<unknown>>([]);
  const [activeUserChartData, setActiveUserChartData] = useState<Array<unknown>>([]);

  const { price } = useContext(McoqPriceContext);

  useEffect(() => {
    getBetsChartData().then((chartsData) => setBetsChartData(chartsData));
    getTotalsData().then((totals) => setTotalsData(totals));
    getPnlChartData().then((chartsData) => setPnlChartData(chartsData));
    getDepositWithdrawalData().then((chartsData) => {
      const combinedData = chartsData.weeklyDeposits.map((deposit: any, index: number) => ({
        ...deposit,
        total_withdrawals: chartsData.weeklyWithdrawals[index]?.total_withdrawals
      }));
      setDepositWithdrawalChartData(combinedData);
    });
    getActiveUserChartData().then((chartsData) => setActiveUserChartData(chartsData));
  }, []);

  const renderLineChart = (data: any, lines: { dataKey: string; color: string; name: string }[], xAxisKey: string) => (
    <Grid item xs={12} sm={6}>
      <ResponsiveContainer width="100%" height={320}
        style={{ backgroundColor: '#12141B', borderRadius: '16px', paddingTop: '20px' }}>
        <LineChart data={data} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          {lines.map(line => (
            <Line key={line.dataKey} type="monotone" dataKey={line.dataKey} stroke={line.color} activeDot={{ r: 8 }} name={line.name} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );

  const renderBarChart = (data: any, lines: { dataKey: string; color: string; name: string }[], xAxisKey: string) => (
    <Grid item xs={12} sm={6}>
      <ResponsiveContainer width="100%" height={320}
        style={{ backgroundColor: '#12141B', borderRadius: '16px', paddingTop: '20px' }}>
        <BarChart data={data} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          {lines.map(line => (
            <Bar key={line.dataKey} type="monotone" dataKey={line.dataKey} stroke={line.color} name={line.name} activeBar={<Rectangle fill="pink" stroke="blue" />}  />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );

  const renderTotalContainer = (title: string, value: number) => (
    <Grid item xs={12} sm={6} md={3}>
      <Box textAlign="center"
        sx={{
          backgroundColor: '#12141B',
          borderRadius: '6px',
          paddingY: '8px',
          height: '110px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Typography color={pink} sx={{ fontSize: 21 }}>
          {getFormattedTotal(value)} {!title.includes('users') && 'MCOQ'}
        </Typography>
        <Typography color={green}>
          {!title.includes('users') && '$' + getFormattedTotal(value * price)}
        </Typography>
        <Typography color={greyWhite}>
          {title}
        </Typography>
      </Box>
    </Grid>
  )

  return (
    <Container maxWidth="lg" sx={{ marginTop: 1 }}>
      <Grid container spacing={4} justifyContent="center">
        {renderTotalContainer('Total active users', totalsData.totalUsers)}
        {renderTotalContainer('Total Volume', totalsData.totalVolume)}
        {renderTotalContainer('Total House PNL', totalsData.totalPnl)}
        {renderTotalContainer('Total coqburn', totalsData.totalBurn)}
      </Grid>
      <Grid container spacing={4} justifyContent="center" mt={1}>
        {renderLineChart(activeUserChartData.slice(0,-1), [
          { dataKey: 'count', color: pink, name: 'Acitve users (Weekly)' }
        ], 'start_date')}
        {renderLineChart(betsChartData.slice(0,-1), [
          { dataKey: 'volume', color: pink, name: 'MCOQ Bet Volume (Weekly)' }
        ], 'start_date')}
        {renderLineChart(depositWithdrawalChartData.slice(0,-1), [
          { dataKey: 'total_deposits', color: green, name: 'Total Deposits (Weekly)' },
          { dataKey: 'total_withdrawals', color: pink, name: 'Total Withdrawals (Weekly)' }
        ], 'start_date')}
        {renderBarChart(pnlChartData.slice(0,-1), [
          { dataKey: 'total_pnl', color: pink, name: 'House PNL in MCOQ (Weekly)' }
        ], 'start_date')}
      </Grid>
    </Container>
  );
}

export default Stats;
