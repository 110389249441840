import { styled, Button } from "@mui/material";

export const BetFilterButton = styled(Button)({
  maxWidth: '100px',
  minWidth: '70px',
  height: '32px',
  borderRadius: '64px',
  color: "#fff",
  backgroundColor: "rgb(41, 41, 41)",
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.75,
  padding: '6px 8px',
  border: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: "100%",
  textTransform: 'none',
  '&:hover': {
    backgroundColor: "rgb(41, 41, 41)",
  },
});
