import { Box, useMediaQuery, useTheme } from '@mui/material';

import Cell from './Cell';
import Footer from './Footer';

import { gameFieldStyles } from '../../utils';

const cellsAmount = 40;
const maxSelectedCells = 10;

const KenoGrid = ({ isXL }: { isXL: boolean }) => {
  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      maxWidth: isXL ? '725px' : '600px',
      marginX: 'auto',
      flexGrow: 1,
    }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
          gap: '10px',
          width: '100%'
        }}
      >
        {Array.from({ length: cellsAmount }, (_, i) => (
          <Cell number={i + 1} maxSelectedCells={maxSelectedCells} key={`gencell${i}`} />
        ))}
      </Box>
    </Box>
  );
};

const GameField = () => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box sx={{ flexGrow: { xl: 1 } }}>
      <Box sx={gameFieldStyles}>
        <KenoGrid isXL={isXL} />
        <Footer maxSelectedCells={maxSelectedCells} />
      </Box>

    </Box>
  );
};

export default GameField;
