import { Box, useMediaQuery, useTheme } from "@mui/material"
import { colors } from "../utils/colors";

const BetMenuWrapper = ({ children }) => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box sx={{
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      width: isXL ? '400px' : '100%',
      minHeight: { xl: '700px' },
      paddingX: '16px',
      paddingY: '20px'
    }}>
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: isXL ? '700px' : 'auto',
        paddingX: '16px',
        backgroundColor: colors.darkGrey,
        borderRadius: '8px',
      }}>
        <Box sx={{ width: '100%', paddingY: '24px' }}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default BetMenuWrapper