export const colors = {
  grey800: '#262334',
  freshGrey: '#4e586d',
  freshWhite: '#E5E7EB',
  neutral700: '#374151',
  grey500: '#6B7280',
  yellow: '#d7ff00',
  darkGrey: '#14121e',
  lightYellow: '#ecffa4',
  borderGrey: '#444054',
  borderHover: '#B1BAD3',
  textWhite: '#fff',
  lightGreen: '#00e701',
  highlightGreen: '#02de82',
  highlightRed: '#FF3B69',
  boxShadowGreen: '#00b96b',
  darkGreyPurple: '#1d1b29',
  pink: 'rgba(236, 95, 89)',
  transparentPink: 'rgba(236, 95, 89, 0.5)'
};

export const green = '#4caf50';
export const pink = '#ec5f59';
export const greyWhite = '#f5f5f5';