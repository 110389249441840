export const getFormattedTotal = (value: number) => {
  const formattedValue = value.toLocaleString('en-US', { maximumFractionDigits: 2 });
  if (formattedValue.split('.')[1] === '00') {
    return formattedValue.split('.')[0];
  }
  return formattedValue;
}

export const getDiceMultiplier = ({ winChance, houseEdge }: { winChance: number, houseEdge: number }) => {
  const multiplier = 100 * houseEdge / winChance;
  return Number(multiplier.toFixed(4));
}
