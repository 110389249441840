import { useContext } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";

import PercentIcon from '@mui/icons-material/Percent';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import { DiceContext } from "../../../../contexts/DiceContext";
import { textFieldStyles } from "../../utils";

const Footer = () => {
  const {
    target, winChance,
    condition, toggleCondition,
    currentMultiplier,
    setTargetAndWinChance
  } = useContext(DiceContext)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      setTargetAndWinChance(undefined, value);
    }
  }

  const handleBlur = () => {
    if (target > 98) {
      setTargetAndWinChance(98);
    } else if (target < 2) {
      setTargetAndWinChance(2);
    }

    setTargetAndWinChance(undefined, Number(winChance.toFixed(2)));
  }

  const handleInputError = (label: string, value: number): string => {
    if (['Win Chance', condition].includes(label)) {
      if (value < 0.01 || value > 98) {
        return 'Value out of range (2 - 98)';
      }
      return "";
    }

    if (label === 'Multiplier') {
      if (value < 1 || value > 49) {
        return 'Value out of range (1.00 - 49.00)';
      }
      return "";
    }
    return "";
  }

  const renderTextField = (
    { label, value, adornmentIcon, readOnly = false, onChange, onClick, onBlur }:
      {
        label: string,
        value: number,
        adornmentIcon: JSX.Element,
        readOnly: boolean,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        onClick?: () => void,
        onBlur?: () => void,
      }
  ) => (
    <Box sx={{ width: '100%', textAlign: 'left' }} >
      <Typography sx={{ color: '#fff' }}>{label.charAt(0).toUpperCase() + label.slice(1)}</Typography>
      <TextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        type="number"
        error={handleInputError(label, value) ? true : false}
        helperText={handleInputError(label, value)}
        inputProps={{
          min: 2,
          max: 98,
          inputMode: 'decimal',
          pattern: '^[0-9]*[.,]?[0-9]*$',
          readOnly: readOnly,
        }}
        sx={{
          ...textFieldStyles,
          '& .MuiOutlinedInput-root': {
            ...textFieldStyles['& .MuiOutlinedInput-root'],
            '& .MuiInputBase-input': {
              ...textFieldStyles['& .MuiOutlinedInput-root']['& .MuiInputBase-input'],
              cursor: onClick ? 'pointer' : 'auto',
            },
          },
        }}
        InputProps={{
          style: { cursor: onClick ? 'pointer' : 'auto' },
          endAdornment: (
            <InputAdornment position="end" sx={{ color: '#fff' }}>
              {adornmentIcon}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { lg: 'row', xs: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        p: { xs: '0px', lg: '16px' },
        width: '100%',
        columnCount: 3,
      }}
    >
      {renderTextField({
        label: 'Multiplier',
        value: currentMultiplier,
        readOnly: true,
        adornmentIcon: <CloseIcon />,
        onChange: () => { },
      })}
      {renderTextField({
        label: condition,
        value: target,
        readOnly: true,
        adornmentIcon: <RefreshIcon />,
        onChange: () => { },
        onClick: toggleCondition
      })}
      {renderTextField({
        label: 'Win Chance',
        value: winChance,
        readOnly: false,
        adornmentIcon: <PercentIcon />,
        onChange: handleChange,
        onBlur: handleBlur
      })}
    </Box>
  );
}

export default Footer;
