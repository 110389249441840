import { useState, useEffect } from "react";
import { Box, Toolbar, Typography } from "@mui/material";
import { BETFILTEROPTIONS, BETTIMEOPTIONS } from "../../utils/config";
import Bets from "./Bets";
import { BetFilterButton } from "./BetFilterButton";
import FilterBetByTime from "./FilterBetByTime";
import InputFilter from "./InputFilter";
import { getIntervalDates } from "../../utils/timerange.util";
import { BetStreamProvider } from "../../contexts/BetStreamContext";

const Betstream = () => {
  const [settlementFilter, setSettlementFilter] = useState(BETFILTEROPTIONS[0]);
  const [selectedTime, setSelectedTime] = useState(BETTIMEOPTIONS[3]);
  const [selectedRange, setSelectRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const [stringDateRange, setStringDateRange] = useState({
    startDate: "",
    endDate: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setStringDateRange(getIntervalDates(selectedTime, {
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate
    }));
  }, [selectedTime, selectedRange]);

  const getFilterBySettlement = (settlement) => {
    if (settlement === BETFILTEROPTIONS[0]) return '';
    if (settlement === BETFILTEROPTIONS[1]) return 'open';
    return settlement.toLowerCase();
  };

  return (
    <BetStreamProvider>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100%',
          backgroundColor: '#000',
          overflow: 'hidden',
          p: '16px',
          m: '0 auto',
          maxWidth: '100%',
        }}
      >
        <Toolbar variant="dense" />
        <Box sx={{
          flex: 1, overflow: 'auto', position: 'relative',
          '::-webkit-scrollbar': {
            width: '8px',
          },
          '::-webkit-scrollbar-track': {
            background: '#1c2327',
            borderRadius: '8px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#ec5f59',
            borderRadius: '8px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#b24b45',
          },
        }}>
          <Box sx={{
            position: 'relative',
            top: '24px',
            left: '0px',
            paddingBottom: '8px',
            borderBottom: '1px solid #ec5f59',
            width: '100%',
          }}>
            <Typography variant="h4" color={"#fff"}>
              Bet Activity
            </Typography>
          </Box>
          <Box sx={{
            marginTop: '40px',
            borderRadius: '8px',
            paddingLeft: '0px',
            paddingBottom: '24px',
            backgroundColor: '#000',
            width: '100%',
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}>
              <Box sx={{ display: 'flex', gap: '8px', width: '100%', minWidth: 'max-content', maxWidth: '540px' }}>
                {BETFILTEROPTIONS.map(option => (
                  <BetFilterButton
                    disabled={isLoading}
                    key={option}
                    onClick={() => setSettlementFilter(option)}
                    sx={{
                      backgroundColor: settlementFilter === option ? 'rgb(255, 82, 82)' : 'rgb(41, 41, 41)',
                      '&:hover': {
                        backgroundColor: settlementFilter === option ? 'rgb(255, 82, 82)' : 'rgb(41, 41, 41)',
                      }
                    }}
                  >
                    {option}
                  </BetFilterButton>
                ))}
              </Box>
              <InputFilter />
              <FilterBetByTime
                selectedTime={selectedTime}
                selectedRange={selectedRange}
                handleRangeSelect={setSelectRange}
                handleTimeSelect={setSelectedTime}
                handleTimeFilterChange={() => { }}
                handleTimeRangeChange={e => {
                  console.log(e)
                  setSelectRange({
                    startDate: e.startDate,
                    endDate: e.endDate,
                    key: 'selection'
                  });
                }}
              />
            </Box>
            <Bets setIsLoading={setIsLoading} stringDateRange={stringDateRange} settlementFilter={getFilterBySettlement(settlementFilter)} />
          </Box>
        </Box>
      </Box>
    </BetStreamProvider>
  );
}

export default Betstream;
