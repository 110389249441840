import React, { useEffect, useContext } from 'react';
import { useReadContract } from 'wagmi';
import { TRADER_JOE_ROUTER, COQ_CONTRACT_ADDRESS, WAVAX_CONTRACT_ADDRESS, USDC_CONTRACT_ADDRESS, MCOQ_PRICE } from '../../../utils/config';
import { Box, Skeleton } from '@mui/material';

import { formatEther, formatUnits } from 'viem';

import { McoqPriceContext } from '../../../contexts/McoqPriceContext';

const contractFunction = 'getAmountsOut';
const joeRouterAbi = [
  {
    constant: true,
    inputs: [
      {
        name: 'amountIn',
        type: 'uint256'
      },
      {
        name: 'path',
        type: 'address[]'
      }
    ],
    name: 'getAmountsOut',
    outputs: [
      {
        name: 'amounts',
        type: 'uint256[]'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  }
]

const TokenPrice = () => {
  const { price, setPrice } = useContext(McoqPriceContext);

  const coqToWavax = useReadContract({
    address: TRADER_JOE_ROUTER,
    abi: joeRouterAbi,
    functionName: contractFunction,
    args: [BigInt(1e18), [COQ_CONTRACT_ADDRESS, WAVAX_CONTRACT_ADDRESS]] as const,
  });

  const wavaxToUsdc = useReadContract({
    address: TRADER_JOE_ROUTER,
    abi: joeRouterAbi,
    functionName: contractFunction,
    args: [BigInt(1e18), [WAVAX_CONTRACT_ADDRESS, USDC_CONTRACT_ADDRESS]] as const,
  });

  useEffect(() => {
    if (coqToWavax.data && wavaxToUsdc.data) {
      const wavaxAmount = Number(formatEther(coqToWavax.data[1], 'wei'));
      const usdcAmount = Number(formatUnits(wavaxToUsdc.data[1], 6))
      const mcoqToUsdcPrice = usdcAmount * wavaxAmount * MCOQ_PRICE;
      setPrice(mcoqToUsdcPrice);
    }
  }, [coqToWavax.data, wavaxToUsdc.data]);

  if (coqToWavax.isLoading || wavaxToUsdc.isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Skeleton variant="text" width={50} />
      </Box>
    );
  }

  if (coqToWavax.isError || wavaxToUsdc.isError) {
    return <p>Error</p>;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {price !== 0 ? (
        <div style={{ fontWeight: 700, fontSize: 16, margin: 1 }}>${price.toFixed(2)}</div>
      ) : (
        <Skeleton variant="text" width={50} />
      )}
    </Box>
  )

};

export default TokenPrice;
