import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import { Box, Typography, styled, Grid } from "@mui/material";

import { useQuery } from '@apollo/client';
import { GET_BETS, GET_BET } from '../../graphql/queries';
import { PARTNER_ID } from '../../utils/config';
import { UserDetailsContext } from '../../contexts/UserContext';
import { BetStreamContext } from '../../contexts/BetStreamContext';

import BetCardSkeleton from './BetCardSkeleton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BetCard from './BetCard';

const LoadingIcon = styled(AutorenewIcon)(({ theme }) => ({
  animation: 'spin 1s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  color: '#ec5f59',
  fontSize: '2rem',
  margin: '16px auto',
  position: 'absolute',
  bottom: '-32px',
  left: '50%',
  zIndex: 10,
}));

type TimeRange = {
  startDate: string;
  endDate: string;
}
const Bets = ({ stringDateRange, settlementFilter, setIsLoading }:
  { stringDateRange: TimeRange, settlementFilter: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { userJWT } = useContext(UserDetailsContext);
  const {
    address,
    setBetId, betId } = useContext(BetStreamContext);
  const [startIndex, setStartIndex] = useState(0);
  const [bets, setBets] = useState<Set<any>>(new Set());
  const [hasMore, setHasMore] = useState(true);

  const { loading, error, fetchMore, refetch, startPolling, stopPolling, data } = useQuery(GET_BETS, {
    context: {
      headers: {
        "authorization": `Bearer ${userJWT}`,
        "x-aji-partner-id": PARTNER_ID,
      }
    },
    skip: !userJWT || betId !== '',
    variables: { startIndex: 0, count: 20, ...stringDateRange, settlement: settlementFilter },
    fetchPolicy: 'network-only'
  });

  const { loading: loadingBet, error: errorBet, refetch: refetchBet, data: dataBet } = useQuery(GET_BET, {
    context: {
      headers: {
        "authorization": `Bearer ${userJWT}`,
        "x-aji-partner-id": PARTNER_ID,
      },
    },
    skip: !betId,
    variables: { id: betId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setIsLoading(loading || loadingBet);
  }, [loading, loadingBet, setIsLoading]);

  useEffect(() => {
    startPolling(10000);
    return () => stopPolling();
  }, [startPolling, stopPolling, bets]);

  useEffect(() => {
    if (!loading && data && data.bets) {
      const newFetchedBets = data.bets.filter(
        (bet: any) => !bets.has(bet)
      );

      if (newFetchedBets.length > 0) {
        setBets((prevBets) => new Set([...data.bets, ...prevBets,]));
      }
    }
  }, [data]);

  useEffect(() => {
    if (userJWT) {
      setBetId('')
      setStartIndex(0);
      setHasMore(true);
      setBets(new Set());
      refetch().then(({ data }) => setBets(new Set(data.bets)));
    }
  }, [stringDateRange, userJWT, refetch, settlementFilter]);

  useEffect(() => {
    if (userJWT && betId) {
      setStartIndex(0);
      setHasMore(false);
      setBets(new Set());
      refetchBet({ id: betId }).then(({ data }) => setBets(new Set([data.bet])));
    } else if (userJWT && !betId) {
      setBetId('')
      setStartIndex(0);
      setHasMore(true);
      setBets(new Set());
      refetch().then(({ data }) => setBets(new Set(data.bets)));
    }
  }, [betId]);

  useEffect(() => {
    if (!hasMore || !bets.size) return
    fetchMore({
      variables: {
        startIndex: startIndex,
        count: 20,
        ...stringDateRange,
        settlement: settlementFilter
      }
    }).then(({ data }) => {
      setBets((prevBets) => new Set([...prevBets, ...data.bets]))
      if (data.bets.length < 20) {
        console.log('no more bets');
        setHasMore(false);
      }
    });
  }, [startIndex]);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastBetElementRef = useCallback(node => {
    if (loading || !hasMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setStartIndex(prevStartIndex => prevStartIndex + 20);
      }
    }, { threshold: 1 });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  if (!userJWT) return <Typography variant='h5' color={'#fff'}>Log in to see bet activity.</Typography>;
  if (error || errorBet) return <Typography variant='h5' color={'#fff'}>Error: {(error || errorBet || {}).message}</Typography>;

  if (!bets.size && userJWT && !loading && !loadingBet) return <Typography variant='h5' color={'#fff'}>No bets</Typography>;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'start',
      height: '100%',
      width: '100%',
      color: '#fff',
      p: '16px',
      m: '0 auto',
      backgroundColor: '#000',
      position: 'relative',
    }}>
      <Grid container spacing={2}>
        {!bets.size ? (
          Array.from(new Array(20)).map((_, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={`bet-skeleton${index}`}>
              <BetCardSkeleton />
            </Grid>
          ))
        ) : (
          Array.from(bets).map((bet, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={`betcard${index}`} ref={bets.size === index + 1 ? lastBetElementRef : null}>
              <BetCard bet={bet} />
            </Grid>
          ))
        )}
      </Grid>
      <LoadingIcon sx={{ display: hasMore ? 'block' : 'none' }} />
    </Box>
  );
};

export default Bets
