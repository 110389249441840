import { Box, Toolbar, Typography } from "@mui/material";

const PageWrapper = ({ title, children }: { title?: string, children: React.ReactNode }) => {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        color: '#fff',
        p: '16px',
        pb: '0px',
        m: '0 auto',
        maxWidth: '1300px',
        backgroundColor: '#000'
      }}
    >
      <Toolbar variant="dense" />
      <Box sx={{
        flex: 1,
        position: 'relative',
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        <Box
          sx={{
            position: 'relative',
            top: '24px',
            left: '0px',
            paddingBottom: '8px',
            marginBottom: '42px',
            borderBottom: '1px solid #ec5f59',
          }}>
          <Typography variant="h4" color={"fff"}>
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </Box >
  );
}

export default PageWrapper
